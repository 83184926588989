import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { authContext } from "../App";
import Login from "../pages/auth/login";
import Home from "../pages/home";
import ProtectedLayout from "./ProtectedLayout";

const components = [
  {
    path: "/login",
    Component: Login,
  },
  {
    path: "/home",
    Component: Home,
    protected: true,
  },
];

export default function Routers() {
  const renderRoutes = () => {
    return (
      <>
        {components.map((item) => {
          // Authenticate user
          const component = item.protected
            ? Protected(item.Component)
            : item.Component;

          return <Route {...item} Component={component} />;
        })}
      </>
    );
  };

  return (
    <>
      <Routes>
        {renderRoutes()}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </>
  );
}

const Protected = (Components: Function) => {
  const { authToken } = useContext<any>(authContext)
  return () => {
    if (!authToken) {
      return <Navigate to="/login" />;
    }
    return ProtectedLayout(Components);
  };
};
