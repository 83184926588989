import React, { createContext, useState } from "react";
import { Share, ShareApiContextType } from "../../types/stokeSearchBar.type";
import StockSearchBar from "./components/StokeSearchBar";
import axios from 'axios'
import { useGetWatchListData } from "../../hooks/useGetWatchListData";
import StockTable from "./components/StokesTable";
import { BASE_API } from "../../App";
import { getAuthToken } from "../../hooks/useAuth";
import { successNotification } from "../../utils/notifyHelper";

export const ApiContext = createContext<ShareApiContextType | undefined>(
  undefined
);

const StockDashboard: React.FC = () => {
  const [apiData, setApiData] = useState<Share[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedStokes, setSelectedStokes] = useState<string | null>(null);
  const { fetchData: fetchWatchListData, data: watchListData, loading: isWatchListLoading, total, updateStocks } = useGetWatchListData()

  const handleStockSelect = (selectedStoke: string) => {
    setSelectedStokes(selectedStoke);
  };

  const addToWatchList = async (stock_id?: string, isDelete = false) => {
    try {
      setLoading(true);

      const config: any = {
        method: 'POST',
        url: BASE_API + '/apis/share',
        headers: {
          Authorization: 'Bearer ' + getAuthToken()
        }
      };

      if (isDelete) {
        config.data = {
          instrument_token: stock_id,
        }
      } else {
        config.data = {
          instrument_token: selectedStokes,
          watch_list: 'default',
        }
      }

      const response = await axios.request(config);

      if (response.status === 200) {
        successNotification(response?.data?.message || isDelete ? "Share removed from watchlist successfully!" : 'Shares added to watchlist successfully!');
        if (isDelete) {
          updateStocks(watchListData.filter((e: any) => e?._id !== stock_id))
          fetchWatchListData(1);
        } else {
          setApiData([])
          setSelectedStokes(null)
          updateStocks(watchListData.filter((e: any) => e?._id !== stock_id))
          fetchWatchListData(1);
        }
      } else {
        console.error('Error adding shares to watchlist:', response.statusText);
      }
    } catch (error) {
      console.error('Error adding shares to watchlist:', error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <ApiContext.Provider value={{ options: apiData, setApiData, selectedStokes, fetchWatchListData, watchListData, isWatchListLoading, total, addToWatchList }}>
      <div className="stock-dashboard">
        <StockSearchBar onSelect={handleStockSelect} onAdd={addToWatchList} onAddLoader={loading} />
        <StockTable />
      </div>
    </ApiContext.Provider>
  );
};

export default StockDashboard;
