import { useContext } from 'react';
import { authContext } from '../App';
import Header from './Header';

const ProtectedLayout = (Component: Function) => {
  const { logout } = useContext<any>(authContext)
  const handleSignOut = () => {
    // Logic for sign out
    logout()
  };

  return (
    <div className="layout">
      <Header userName="Market Analyzer" onSignOut={handleSignOut} />
      <Component />
    </div>
  );
};

export default ProtectedLayout;
