import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_API } from '../App';
import { errorNotification, successNotification } from '../utils/notifyHelper';

export const getAuthToken = () => localStorage.getItem('authToken');

const useAuth = () => {
    const [authToken, setAuthToken] = useState<string | null>(getAuthToken());
    const [loading, setIsLoading] = useState<boolean>(false)
    const navigate = useNavigate()

    useEffect(() => {
        setAuthToken(localStorage.getItem('authToken'))
    }, [])

    const login = async (email: string, password: string, byPass = false) => {
        if (byPass) {
            localStorage.setItem('authToken', 'xyz');
            setAuthToken('xyz');
            navigate('/home')
        } else {
            try {
                setIsLoading(true)
                const data = {
                    email, password
                };

                const response: any = await axios.post(BASE_API + '/apis/login', data);

                if (response.status === 200) {
                    localStorage.setItem('authToken', response.data.accessToken);
                    setAuthToken(response.data.accessToken);
                    successNotification("Logged in successfully.")
                    navigate('/home')
                } else {
                    errorNotification(response?.data?.message || 'Error while login.');
                }
            } catch (error: any) {
                errorNotification(error?.response?.data?.message || 'Error while login.');
            } finally {
                setIsLoading(false)
            }
        }
    };

    const logout = () => {
        // Remove authentication token from browser storage
        localStorage.removeItem('authToken');
        setAuthToken(null);
        successNotification("Logged out successfully.")
        navigate('/login')
    };

    return { authToken, login, logout, loading };
};

export default useAuth;
