import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useShareApiContext } from '../../../hooks/useShareApiContext';
import Loader from './Loader';
import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';


const headers: { label: string; align: 'left' | 'right' }[] = [
    { label: 'Stoke Name', align: 'left' },
    { label: 'Price', align: 'right' },
    { label: 'High', align: 'right' },
    { label: 'Low', align: 'right' },
    { label: 'Upper Circuit', align: 'right' },
    { label: 'Lower Circuit', align: 'right' },
    { label: 'Actions', align: 'right' },
];


export default function StickyHeadTable() {
    const [page, setPage] = React.useState(0);
    const { fetchWatchListData, watchListData = [], isWatchListLoading, total, addToWatchList: deleteStock } = useShareApiContext();
    const intervalRef = React.useRef<any>(null)


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        fetchWatchListData(page)
    };

    const getLiveData = React.useCallback(() => {
        if (watchListData.length > 0) {
            const instrumentIds = watchListData.map((e: any) => e?.instrument_token)
            fetchWatchListData(null, null, instrumentIds)
        }
    }, [watchListData, fetchWatchListData])


    const handleDelete = (stockId: string) => {
        deleteStock(stockId, true)
        // Perform delete action with stockId
    };

    React.useEffect(() => {
        intervalRef.current = setInterval(() => {
            getLiveData()
        }, 3000)

        return () => {
            clearInterval(intervalRef.current)
            intervalRef.current = null
        }
    }, [getLiveData])

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', position: 'relative' }}>
            <Loader isLoading={isWatchListLoading} />
            <TableContainer className="table-container">
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {headers.map((header) => (
                                <TableCell key={header.label} align={header.align}>
                                    {header.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {watchListData.length > 0 ?
                            watchListData.map((stock: any) => {
                                if (stock) {
                                    return (
                                        <TableRow key={stock._id}>
                                            <TableCell align={headers[0].align}>{stock.name}</TableCell>
                                            <TableCell align={headers[1].align}>{stock.last_price}</TableCell>
                                            <TableCell align={headers[2].align}>{stock.high || '-'}</TableCell>
                                            <TableCell align={headers[3].align}>{stock.low || '-'}</TableCell>
                                            <TableCell align={headers[4].align}>{stock.upper_circuit_limit || '-'}</TableCell>
                                            <TableCell align={headers[5].align}>{stock.lower_circuit_limit || '-'}</TableCell>
                                            <TableCell align={headers[6].align}>
                                                <IconButton aria-label="delete" color="error" onClick={() => handleDelete(stock?.instrument_token)}>
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }

                                return (
                                    <TableRow key={stock.name}>
                                        <TableCell colSpan={7}>No data available for {stock.name}</TableCell>
                                    </TableRow>
                                );
                            }) : (!isWatchListLoading && <TableRow>
                                <TableCell align='center' colSpan={7}>No data available</TableCell>
                            </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={total || 0}
                rowsPerPage={10}
                page={page}
                onPageChange={handleChangePage}
            /> */}
        </Paper >
    );
}

