import { useContext } from "react";
import { ApiContext } from "../pages/home/Home";
import { ShareApiContextType } from "../types/stokeSearchBar.type";

export const useShareApiContext = (): ShareApiContextType => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error('useApiContext must be used within an ApiContextProvider');
  }
  return context;
};