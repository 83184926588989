import React, { useContext, useState } from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { isEmptyObject } from '../../../utils/helper';
import { authContext } from '../../../App';
import { CircularProgress } from '@mui/material';

function validateLoginForm(email: string, password: string): { emailError?: string, passwordError?: string } {
  const errors: { emailError?: string, passwordError?: string } = {};
  // const authEmail = process.env.REACT_APP_AUTH_EMAIL;
  // const authPassword = process.env.REACT_APP_AUTH_PASSWORD;

  // Email validation regex pattern
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!email.trim()) {
    errors.emailError = 'Email is required';
  } else if (!emailRegex.test(email)) {
    errors.emailError = 'Invalid email address';
  }
  // } else if (email !== authEmail) {
  //   errors.emailError = 'Invalid email address';
  // }

  if (!password.trim()) {
    errors.passwordError = 'Password is required';
  }
  // } else if (password !== authPassword) {
  //   errors.passwordError = 'Invalid password';
  // }

  return errors;
}


export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<{ emailError?: string, passwordError?: string }>({})
  const { login, isLoginLoader } = useContext<any>(authContext)


  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Perform form submission logic here, such as API call or validation
    const validations = validateLoginForm(email, password)

    if (!isEmptyObject(validations)) {
      setErrors(validations)
    } else {
      // setEmail('');
      // setPassword('');
      setErrors({})
      login(email, password)
    }
  };


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className="login-container">
        <Avatar className="login-avatar">
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Market Analyser Sign in
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            helperText={errors?.emailError}
            error={!!errors?.emailError}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            // autoComplete="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            inputProps={{
              minLength: 6,
            }}
            helperText={errors?.passwordError}
            error={!!errors?.passwordError}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoginLoader}
          >
            <div className="button-content">
              Sign In
              {isLoginLoader && <CircularProgress size="16px" sx={{color: "#fff"}} />}
            </div>
          </Button>
        </Box>
      </div>
    </Container>
  );
}
