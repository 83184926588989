import { useState, useEffect } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { BASE_API } from '../App';
import { getAuthToken } from './useAuth';

export const useGetWatchListData = () => {
    const [data, setData] = useState<any>([])
    const [total, setTotal] = useState<number>(0)
    const [loading, setLoading] = useState<any>(true);
    const [error, setError] = useState<any>(null);

    let cancelToken: any
    const fetchData = async (page: any, watch_list = "default", instrument_token = []): Promise<void> => {
        try {

            if (typeof cancelToken != typeof undefined) {
                cancelToken.cancel("Operation canceled due to new request.")
            }

            cancelToken = axios.CancelToken.source()

            const config: any = {
                method: 'GET',
                url: BASE_API + '/apis/instruments',
                params: {
                    pageSize: 100,
                },
                headers: {
                    Authorization: 'Bearer ' + getAuthToken()
                },
                cancelToken: cancelToken.token
            }
            if (instrument_token.length > 0) {
                config.params.instrument_token = JSON.stringify(instrument_token)
            } else {
                config.params.page = page
                config.params.watch_list = watch_list
            }
            const response = await axios(config);
            if (response.status === 200) {
                setData(response.data.object.data);
                setTotal(response.data.object.count);
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                // Request was cancelled, no need to handle the error
            } else {
                setError(error);
            }
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData(1);
    }, []);

    return { data, loading, error, fetchData, total, updateStocks: setData };
}
