import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { debounce } from 'lodash';
import SearchIcon from '@mui/icons-material/Search';
import { StockSearchBarProps } from '../../../types/stokeSearchBar.type';
import { useShareApiContext } from '../../../hooks/useShareApiContext';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { BASE_API } from '../../../App';
import { getAuthToken } from '../../../hooks/useAuth';
import axios from 'axios';

const StockSearchBar: React.FC<StockSearchBarProps> = ({ onSelect, onAdd, onAddLoader }) => {
    const [loading, setLoading] = useState(false);
    const { setApiData, options, } = useShareApiContext();


    const fetchShares = async (searchQuery: string) => {
        try {
            setLoading(true);
            const config: any = {
                method: 'GET',
                url: BASE_API + '/apis/instruments',
                params: {
                    exchange: "NSE",
                    page: 1,
                    pageSize: 100,
                    filter: searchQuery
                },
                headers: {
                    Authorization: 'Bearer ' + getAuthToken()
                }
            }
            const response: any = await axios(config);
            if (response.status === 200) {
                setApiData(response?.data?.object?.data?.filter((e: any) => e?.name) || [])
            } else {
                setApiData([]);
            }
        } catch (error) {
            console.error('Error fetching shares:', error);
        } finally {
            setLoading(false);
        }
    };

    const debouncedFetchShares = debounce(fetchShares, 300);

    const handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
        if (value && value?.length && event.type === 'change') debouncedFetchShares(value);
    };

    return (
        <div className="stock-search-bar">
            <div className="search-bar">
                <Autocomplete
                    // multiple
                    // freeSolo
                    forcePopupIcon={false}
                    options={options}
                    loading={loading}
                    getOptionLabel={(option) => option?.name}
                    onChange={(event, value) => {
                        onSelect(value?.instrument_token);
                    }}
                    onInputChange={handleInputChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Select Stocks"
                            placeholder="Search"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {params.InputProps.endAdornment}
                                        {loading ? <CircularProgress color="inherit" size={20} /> : <SearchIcon className="search-icon" />}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            </div>
            <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={onAdd}
                disabled={onAddLoader}
            >
                Add to Watchlist
            </Button>
        </div>
    );
};

export default StockSearchBar;
