import axios from 'axios';
import { useState } from 'react';
import { BASE_API } from '../App';
import { errorNotification, successNotification } from '../utils/notifyHelper';
import { getAuthToken } from './useAuth';

const useSyncStockData = () => {
    const [loading, setIsLoading] = useState<boolean>(false)

    let cancelToken: any
    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.")
    }

    cancelToken = axios.CancelToken.source()

    const syncStockes = async () => {
            try {
                setIsLoading(true)

                const config: any = {
                    method: 'GET',
                    url: BASE_API + '/apis/live-notifications',
                    headers: {
                        Authorization: 'Bearer ' + getAuthToken()
                    },
                    cancelToken: cancelToken.token
                }
                const response = await axios(config);
            if (response.status === 200) {
                successNotification("Stock data synced successfully.")
            }
            } catch (error) {
                if (axios.isCancel(error)) {
                    // Request was cancelled, no need to handle the error
                } else {
                    // setError(error);
                }
            } finally {
                setIsLoading(false);
            }
        }


    return {syncStockes, loading };
};

export default useSyncStockData;
