import { createSlice } from "@reduxjs/toolkit";

interface Login {
  email: string;
  password: string;
}

export const loginSlice = createSlice({
  name: "login",
  initialState: [],
  reducers: {
    authenticateUser: (state: Login[], action: any) => {
      const login = {
        email: action.email,
        password: action.password,
      };
      state.push(login);
    },
  },
});

export const { authenticateUser } = loginSlice.actions;

export default loginSlice.reducer;
