import { ThemeProvider, createTheme } from '@mui/material';
import { createContext } from 'react';
import useAuth from './hooks/useAuth';
import { Routes } from './router';
import './styles/index.scss'

export const BASE_API = process.env.REACT_APP_BASE_API

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#264653',
    },
    secondary: {
      main: '#e76f51',
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
      button: {
        textTransform: 'none'
      }
  },
});

export const authContext = createContext({})
export default function App(): JSX.Element {
  const { authToken, login, logout, loading } = useAuth()
  return (
    <ThemeProvider theme={theme}>
      <authContext.Provider value={{ authToken, login, logout, isLoginLoader: loading }}>
        <Routes />
      </authContext.Provider>
    </ThemeProvider >
  );
}
