import React from 'react';
import { IconButton , Button} from '@mui/material';
import { Logout, Sync, Launch } from '@mui/icons-material';
import useSyncStockData from '../hooks/useSyncStockData';

interface HeaderProps {
    userName: string;
    onSignOut: () => void;
}

const Header: React.FC<HeaderProps> = ({ userName, onSignOut }) => {
    const {syncStockes, loading} = useSyncStockData()
    return (
        <header className="header">
            <div className="header-left">
                {/* <Avatar className="header-avatar" /> */}
                <span className="header-username">{userName}</span>
            </div>
            <div className="header-right">
                <Button variant="contained" startIcon={<Sync />} onClick={syncStockes}>
                    {loading ? 'Syncing' : 'Sync'}
                </Button>
                <Button variant="contained" startIcon={<Launch />} href="https://kite.zerodha.com/connect/login?v=3&api_key=v9z6dvj843gmuqat" target='_blank' title="Login & Connect to Zeroth">
                    Connect
                </Button>
                <IconButton className='icon-white' onClick={onSignOut}>
                    <Logout />
                </IconButton>
            </div>
        </header>
    );
};

export default Header;
